import { useState } from "react";
import Login from "./pages/LogIn/index.jsx";
import Dashboard from "./pages/dashboard/index.jsx";
import FeaturedArtwork from "./pages/featuredartwork/index.jsx";
import AddNewArtwork from "./pages/addnewartwork/addnewartwork.jsx";
import EditArtWork from "./components/editartwork/editartwork.jsx";
import Prompt from "./pages/prompt/index.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/context/PrivateRoute.jsx";


function App() {
  const [count, setCount] = useState(0);

  return (
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="/featuredArtwork" element={<FeaturedArtwork />} />
            <Route element={<EditArtWork />} path="/featuredArtwork/:id" />
            <Route element={<Prompt />} path="/featuredArtwork/prompt/:id" />
            <Route
              element={<AddNewArtwork type={"add"} />}
              path="/featuredArtwork/prompt/add/:id"
            />
            <Route 
            element={<AddNewArtwork type={"edit"} />}
            path="/featuredArtwork/prompt/edit/:id"
            />
          </Route>
          <Route element={<Login />} path="/" />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
