/*
File: this file is responsible for protecting routes from unauthenticated user
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies
import { Navigate, Outlet } from "react-router-dom";

// function
const PrivateRoutes = () => {
  const token = localStorage.getItem("access");
  return token ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateRoutes