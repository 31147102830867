/*
File:this file is responsible for showing list of prompt of specific category
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "../prompt/prompt.module.scss";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import Dashboard from "../dashboard/index";
import Table from "../../components/table/table";
import backspace from "../../assets/backspace.svg";
import blank from "../../assets/blank.jpg"
import RoleDropdown from "../../components/dropdown/dropdown";
import EditPrompt from "../../components/editPprompt/editprompt.jsx";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../config.jsx";
import axiosInstance from "../../api/axiousinstance.jsx";
import { ToastContainer } from "react-toastify";

const options = [
  { value: "Published", label: "Published" },
  { value: "Unpublished", label: "Unpublished" },
  { value: "Draft", label: "Draft" },
];

function Prompt() {
  const token = localStorage.getItem("access");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("catName");
  let Cname = name.charAt(0).toUpperCase() + name.slice(1);
  // const name = params.catName
  // console.log(name)
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  // console.log(tableData)
  const [isOpen, setISOpen] = useState(false);
  function getList() {
    axiosInstance
      .get(
        `/api-admin/featured_artwork/artworkmodel/list/?category=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTableData(res.data.data.items);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getList();
  }, []);
  const handleBackClick = () => {
    navigate(-1);
  };
  const handleRoleChange = useCallback((rowIndex, value) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex], status: value };
      return newData;
    });
  }, []);

  const handleButtonClick = (event) => {
    event.preventDefault();
    axiosInstance.post(
      `/api-admin/featured_artwork/artworkmodel/add/`,
      {
        name: tableData.title,
        order: tableData.order,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "Cover",
        accessor: (row) =>  row.artwork_image,
        Cell: ({ cell: { value } }) => (
          // console.log(value),
          (
            <img
              src={value || blank}
              alt="Cover"
              style={{ width: "50px", height: "50px", borderRadius: "10px", marginTop:"10px", marginBottom:"10px" }}
            />
          )
        ),
      },
      {
        Header: "Prompt",
        accessor: "prompt",
      },
      {
        Header: "Aspect Ratio",
        accessor: "ratio",
      },
      {
        Header: "Status",
        accessor: "status",
        // Cell: ({ row }) => (
        //   <RoleDropdown
        //     options={options}
        //     index={row.index}
        //     value={row.original.status}
        //     className={style.featuredDropdown}
        //     onChange={(value) => handleRoleChange(row.index, value)}
        //   />
        // ),
      },
      {
        Header: "View",
        accessor: "view",
        Cell: ({ row }) => <EditPrompt id={id} data={row} name={Cname} />,
      },
    ],
    [handleRoleChange, open]
  );
  return (
    <Dashboard>
      <div className={style.promptContainer}>
        <button onClick={handleBackClick} className={style.buttonStyle}>
          <img src={backspace} alt="" />
          <span>&nbsp;{Cname}</span>
        </button>
        <Link
          to={`/featuredArtwork/prompt/add/${id}?catName=${Cname}`}
          className={style.addCategory}
        >
          Add New Artwork
        </Link>
        <Table columns={columns} tableData={tableData} />
      </div>
      {/* <ToastContainer/> */}
    </Dashboard>
  );
}

export default Prompt;
