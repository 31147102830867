/*
File:this file is responsible for showing list and adding category 
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "../dashboard/dashboard.module.scss";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import Dashboard from "../dashboard/index";
import InputField from "../../components/input/index.jsx";
import Modal from "../../components/modal/modal";
import errorpic from "../../assets/error.svg";
import axios from "axios";
import RoleDropdown from "../../components/dropdown/dropdown";
import Table from "../../components/table/table.jsx";
import EditArtWork from "../../components/editartwork/editartwork.jsx";
import { API_BASE_URL } from "../../config.jsx";
// import { Toast, showToast } from "../../components/toast/toast.jsx";
import axiosInstance from "../../api/axiousinstance.jsx";
import { toast, ToastContainer } from "react-toastify";

const options = [
  { value: "Published", label: "Published" },
  { value: "Unpublished", label: "Unpublished" },
  { value: "Draft", label: "Draft" },
];

const FeaturedArtwork = () => {
  const [errors, setErrr] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setISOpen] = useState(false);
  const [formData, setFormData] = useState({ title: "", order: "" });

  function getData() {
    const token = localStorage.getItem("access");
    axiosInstance
      .get(`/api-admin/featured_artwork/featuredcategorymodel/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTableData(res.data.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const errorMessage =
    "Can’t publish this category minimum one artwork required";

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRoleChange = useCallback((rowIndex, value) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex], status: value };
      return newData;
    });
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const token = localStorage.getItem("access");

    axiosInstance
      .post(
        `/api-admin/featured_artwork/featuredcategorymodel/add/`,
        {
          name: formData.title,
          order: formData.order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log("i am rendering");
        toast("category added successfully", {
          type: "success",
        });
        setLoading(false);
        setISOpen(false);
        setFormData({ title: "", order: "" });
        getData();
      })
      .catch((error) => {
        console.log(error.response.data.errors.order)
        toast.error(error.response.data.errors.order[0]);
        setFormData({ title: "", order: "" });
        // setISOpen(false);
      });
  };

  // Memoize columns to prevent re-creation on every render
  const columns = useMemo(
    () => [
      {
        Header: "Order",
        accessor: "order",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Artwork",
        accessor: "artwork_count",
      },
      {
        Header: "Status",
        accessor: "status",
        // Cell: ({ row }) => {
        //   // console.log(row.original.status)
        //   return (
        //     <RoleDropdown
        //       options={options}
        //       index={row.index}
        //       value={row.original.status}
        //       className={style.featuredDropdown}
        //       onChange={(value) => handleRoleChange(row.index, value)}
        //     />
        //   );
        // },
      },
      {
        Header: "Options",
        accessor: "option",
        Cell: ({ row }) => <EditArtWork data={row} getData={getData} />,
      },
    ],
    [handleRoleChange, open]
  );

  return (
    <>
      {/* <Toast /> */}

      <Dashboard>
        <div className={style.contentContainer}>
          {!errors ? (
            <button
              onClick={() => setISOpen(true)}
              className={style.addCategory}
            >
              Add New Category
            </button>
          ) : (
            <div className={style.errorMessage}>
              <img src={errorpic} alt="" />
              <span>Error</span>
              {errorMessage}
            </div>
          )}
          {isOpen && (
            <div className={style.popUpBackground}>
              <Modal
                open={isOpen}
                onClose={() => setISOpen(false)}
                handleSubmit={handleSubmit}
              >
                <div className={style.modalContainer}>
                  <div className={style.modalHeading}>Add New Category</div>
                  <form onSubmit={handleSubmit}>
                    <InputField
                      label="Name"
                      type="text"
                      name="title"
                      placeholder=""
                      className={style.inputFieldModal}
                      onChange={handleChange}
                      value={formData.title}
                    />
                    <InputField
                      label="Order"
                      type="number"
                      name="order"
                      placeholder=""
                      className={style.inputFieldModal}
                      onChange={handleChange}
                      value={formData.order}
                    />

                    {/* <button type="submit" className={style.saveModal}>
                      {loading ? (
                        <i className="fa fa-spinner fa-spin"></i> // Example using Font Awesome spinner
                      ) : (
                        "Save as Draft"
                      )}
                    </button> */}
                  </form>
                </div>
              </Modal>
            </div>
          )}
          <Table columns={columns} tableData={tableData} />
        </div>
      </Dashboard>
      <ToastContainer />
    </>
  );
};

export default FeaturedArtwork;
