/*
File: this file is responsible to get input from user
Updated at:
changes: 
Date: 14-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "./input.module.scss";
import React from "react";

function InputField({
  className,
  type,
  label,
  placeholder,
  value,
  defaultValue,
  name,
  onChange,
  error,
  readOnly = false,
}) {
  return (
    <div className={style.inputWrapper}>
      <div className={style.labelOfInput}>
        <label htmlFor={label}>{label}</label>
      </div>
      <input
        type={type}
        id={label}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
        readOnly={readOnly}
        defaultValue={defaultValue}
      />
      {error && <span className={style.error}>{error}</span>}
    </div>
  );
}
export default InputField;
