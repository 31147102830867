/*
File: this file is responsible for edit category 
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "./editartwork.module.scss";
import React from "react";
import Modal from "../modal/modal.jsx";
import InputField from "../input/index.jsx";
import RoleDropdown from "../dropdown/dropdown.jsx";
import view from "../../assets/view.png";
import edit from "../../assets/edit.png";
import { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config.jsx";
// import { showToast, Toast } from "../toast/toast.jsx";
import axiosInstance from "../../api/axiousinstance.jsx";
import { toast, ToastContainer } from "react-toastify";

const options = [
  { value: "Published", label: "Published" },
  { value: "Unpublished", label: "Unpublished" },
  { value: "Draft", label: "Draft" },
];

// functions for edit category of featured artwork
function EditArtWork(props) {
  const id = props.data.original.id;
  const name = props.data.original.name;
  const [open, setIsOpen] = useState(false);
  // const name = props.data.original.name
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: props.data.original.name,
    order: props.data.original.order,
    status: props.data.original.status,
  });

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // console.log(formData);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const token = localStorage.getItem("access");
    const id = props.data.original.id;

    if (!id) {
      // console.error("Missing Id");
      return;
    }

    axiosInstance
      .patch(
        `/api-admin/featured_artwork/featuredcategorymodel/${id}/change/`,
        {
          name: formData.name,
          order: formData.order,
          status: formData.status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsOpen(false);
        props.getData();
        toast("Category Updated Successfully");
        // showToast("Category Updated Successfully");
      })
      .catch((error) => {
        toast(error.response.data.order[0])
        // toast("somethinmg went wrong")
        // showToast("Something went wrong");
        // console.log(error);
      });
  };

  const handleRoleChange = useCallback((selectedOption) => {
    setFormData({ status: selectedOption });
  }, []);

  return (
    <>
      {/* <Toast /> */}
      <div className={style.contentContainer}>
        <button type="button" onClick={() => setIsOpen(true)}>
          <img src={edit} alt="edit" />
        </button>
        {open && (
          <div className={style.popUpBackground}>
            <Modal
              open={open}
              onClose={() => setIsOpen(false)}
              handleSubmit={handleSubmit}
            >
              <div className={style.modalContainerEdit}>
                <div className={style.modalHeadingEdit}>View</div>
                <div>
                  <form onSubmit={handleSubmit} className={style.editFeatured}>
                    <InputField
                      label="Title"
                      type="text"
                      name="name"
                      placeholder=""
                      className={style.inputFieldModalEdit}
                      onChange={handleEditChange}
                      value={formData.name}
                    />
                    <InputField
                      label="Order"
                      type="number"
                      name="order"
                      placeholder=""
                      onChange={handleEditChange}
                      className={style.inputFieldModalEdit}
                      value={formData.order}
                    />
                    <RoleDropdown
                      className={style.featuredStatus}
                      options={options}
                      name="status"
                      value={formData.status}
                      onChange={handleRoleChange}
                    />

                    {/* <button type="submit" className={style.saveButton}>
                      {loading ? (
                        <i className="fa fa-spinner fa-spin"></i> // Example using Font Awesome spinner
                      ) : (
                        "Save"
                      )}
                     
                    </button> */}
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        )}

        <Link
          to={`/featuredArtwork/prompt/${id}?catName=${name}`}
          className={style.viewStyle}
        >
          <img src={view} alt="view" />
        </Link>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}

export default EditArtWork;
