/*
File: this file is responsible for button component
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies
import style from "./button.module.scss";
import React from "react";
import PropTypes from "prop-types";

const Button = ({
  text,
  imgSrc,
  imgAlt,
  onClick,
  children,
  className,
  label,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      label={label}
      className={`${style.button} ${className}`}
      {...props}
    >
      {/* {imgSrc && (
        <img src={imgSrc} alt={imgAlt} className={style.buttonImage} />
      )} */}
      {/* {children} */}
      {text}
    </button>
  );
};
Button.propTypes = {
  text: PropTypes.string.isRequired,
  // imgSrc: PropTypes.string,
  // label:PropTypes.string,
  // imgAlt: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  // children: PropTypes.node,
  className: PropTypes.string,
};
Button.defaultProps = {
  onClick: () => {},
  // imgSrc: null,
  // type:'button',
  // imgAlt: "",
  // children: null,
  className: "",
};

export default Button;
