/*
File: this file is responsible for component of dropdown
Updated at:
changes: 
Date: 14-07-2024
Author: Ahmad,
*/
// dependencies,
import React from "react";

// function
const RoleDropdown = ({
  value,
  className,
  className1,
  className2,
  onChange,
  options,
  label,
  defaultValue,
  rowIndex,
}) => {
  const backgroundColor =
    value === "Unpublished"
      ? "#FB4141"
      : value === "Published"
      ? "#00B674"
      : value === "Draft"
      ? "#FFC107"
      : "";

 
  return (
    <div className={className1}>
      {className2 && (
        <div className={className2}>
          <label htmlFor="">{label}</label>
        </div>
      )}
      <div>
        <select
          onChange={(e) => onChange(e.target.value)}
          className={className}
          style={{ background: backgroundColor }}
          value={value}
        >
          {/* {/* <option value="" disabled selected> */}
          {label}
          {/* </option> */}
          {options
            ? options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))
            : null}
        </select>
      </div>
    </div>
  );
};

export default RoleDropdown;
