/*
File: this file is responsible for edit artwork 
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies,
import React from "react";
import view from "../../assets/view.png";
import { useNavigate } from "react-router-dom";

// function
function EditPrompt(props) {
  const navigate = useNavigate();
  const data = props.data.original;
  const catid = props.data.original.id;
  // console.log(props.data.original)
  // console.log(props)
  const id = props.id;
  const handleImageClick = () => {
    navigate(`/featuredArtwork/prompt/edit/${id}?catName=${props.name}&catId=${catid}`, {
      state: { data },
    });
  };

  return (
    <div>
      <div onClick={handleImageClick} style={{ cursor: "pointer" }}>
        <img src={view} alt="view" />
      </div>
    </div>
  );
}

export default EditPrompt;
