// /*
// File: this file is responsible for showing table, list of data
// Updated at:
// changes:
// Date: 18-07-2024
// Author: Ahmad,
// */

// // dependencies,
// export default Table;
import style from "./table.module.scss";
import React from "react";
import prev from "../../assets/prev.png";
import next from "../../assets/next.png";
import { useTable, usePagination } from "react-table";

function Table({ columns, tableData }) {
  const tableInstance = useTable(
    { columns, data: tableData, initialState: { pageSize: 10 } },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    canPreviousPage,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    pageCount,
    previousPage,
    gotoPage,
    nextPage,
    setPageSize,
  } = tableInstance;

  return (
    <>
      <table {...getTableProps()}>
        <thead className={style.headingRow}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                key={rowIndex}
                {...row.getRowProps()}
                className={style.tbodyTr}
              >
                {row.cells.map((cell, cellIndex) => {
                  // Determine background color based on cell value if column is "status"
                  const cellprompt =
                    cell.column.id === "prompt" ? style.truncate : "";

                  const cellClass =
                    cell.column.id === "status"
                      ? cell.value === "Unpublished"
                        ? style.cellStatus
                        : cell.value === "Published"
                        ? `${style.cellStatus} ${style.cellStatusPublished}`
                        : cell.value === "Draft"
                        ? `${style.cellStatus} ${style.cellStatusDraft}`
                        : style.cellStatus
                      : "";
                  return (
                    <td
                      key={cellIndex}
                      {...cell.getCellProps()}
                      className={`
                        ${cellClass} 
                        ${cellprompt}
                        ${
                          cell.column.id === "name"
                            ? `${style.tbodyTd} ${style.specialRow}`
                            : style.tbodyTr
                        }`}
                    >
                      {cell.column.id === "status" ? (
                        <span className={style.statusText}>
                          {cell.render("Cell")}
                        </span>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {tableData.length > 0 ? (
        <div className={style.Pagination}>
          <span>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{
                width: "35px",
                border: "1px solid",
                textAlign: "center",
              }}
            />
          </span>
          <select
            style={{
              marginRight: "1rem",
              border: "0px",
              backgroundColor: "#FFFFFF",
            }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100].map((size) => (
              <option key={size} value={size}>
                Rows per page: <b>{size}</b>
              </option>
            ))}
          </select>

          <span>
            Page: {pageIndex + 1} of {pageCount}
          </span>
          <div className={style.pageDirection}>
            <button onClick={previousPage} disabled={!canPreviousPage}>
              <img src={prev} alt="previous" />
            </button>
            <button onClick={nextPage} disabled={pageIndex + 1 === pageCount}>
              <img src={next} alt="next" />
            </button>
          </div>
        </div>
      ) : (
        <div className={style.NoData}>No data</div>
      )}
    </>
  );
}

export default Table;
