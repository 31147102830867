/*
File: this file is responsible for rendering dashboard page 
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "./dashboard.module.scss";
import React from "react";
import logo from "../../assets/logo.png";
import profile from "../../assets/profile.png";
import vector from "../../assets/Vector 2.png";
import { NavLink } from "react-router-dom";
import LogoutButton from "../LogIn/logout";
// import { Toast } from "../../components/toast/toast";

function Dashboard({ children }) {
  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  let Firstname = firstname.charAt(0).toUpperCase()+firstname.slice(1)
  let Lastname = lastname.charAt(0).toUpperCase()+lastname.slice(1)

  return (
    <>
      {/* <Toast /> */}
      <div className={style.Container}>
        <div className={style.headerContainer}>
          <img src={profile} alt="" className={style.profilePic} />
          {Firstname + " " + Lastname}
          <div className="dropdown">
            <button
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img className={style.imgAdmin} src={vector} alt="" />
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Help
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <LogoutButton />
                </a>
              </li>
            </ul>
          </div>{" "}
        </div>
        <div className={style.mainContainer}>
          <div className={style.sidebarContainer}>
            <div className={style.logo}>
              <img src={logo} alt="LOGO" />
            </div>
            <div className={style.sidebarList}>
              <ul>
                <li>
                  <NavLink to="/dashboard">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink to="/featuredArtwork">Featured Artwork</NavLink>
                </li>
                <li>
                  <a href="">Art Styles</a>
                </li>
              </ul>
            </div>
          </div>
          {children ? (
            children
          ) : (
            <div className={style.dashboardContent}>
              Welcome to the Dashboard
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
