// src/api/axiosInstance.js

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config.jsx";
// import { showToast } from "../components/toast/toast.jsx";

const baseURL = API_BASE_URL;
// const navigate = useNavigate();

const axiosInstance = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("access");
    const refreshToken = localStorage.getItem("refresh");

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      // console.log(decodedToken)
      const isExpired = dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;
      // console.log(isExpired)

      if (isExpired) {
        try {
          const response = await axios.post(
            `${API_BASE_URL}/api/auth/token/refresh/`,
            { refresh_token: refreshToken },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          accessToken = response.data.data.access_token;
          // console.log(response.data.data.access_token)
          // console.log(accessToken)
          localStorage.setItem("access", accessToken);
        } catch (error) {
          console.error("Failed to refresh token", error);
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          localStorage.removeItem("firstname");
          localStorage.removeItem("lastname");
          // navigate("/");
          // showToast("Session expired. Please log in again.");
        }
      }

      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
