/*
File: this file is responsible for logout an authentication user and deleting the tokens
Updated at:
changes: implement API hit 
Date: 20-07-2024
Author: Ahmad,
*/
// dependencies,

import React from "react";
import { useNavigate } from "react-router-dom"; // Assuming you use react-router for routing
import axiosInstance from "../../api/axiousinstance";

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const  token = localStorage.getItem("access")
    try {
      // Call the logout API
      await axiosInstance.post("/api-admin/logout/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Clear token from localStorage (or sessionStorage)
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("firstname");
      localStorage.removeItem("lastname");

      // Redirect to login page or home page after logout
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return <button onClick={handleLogout}>Logout</button>;
};

export default LogoutButton;
