/*
File: main file is responsible for opening modal for multiple task like add category etc
Updated at:
changes: 
Date: 20-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "./modal.module.scss";
import React from "react";

export default function Modal({ open, children, onClose, handleSubmit}) {
  if (!open) return null;

  return (
    <div className={style.modalPopUp}>
      {children}
      <div className={style.modalActionBtn}>
        <button onClick={onClose} className={style.closeModal}>
          Close
        </button>
        <button onClick={handleSubmit} className={style.saveBtn}>
          Save
        </button>
      </div>
    </div>
  );
}
