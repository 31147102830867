/*
File: this file is responsible for add new artwork, prompt
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/

// dependencies,
import style from "./addnewartwork.module.scss";
import React, { useState, useEffect } from "react";
import Dashboard from "../dashboard/index";
import profile from "../../assets/profile.png";
import backspace from "../../assets/backspace.svg";
import deletebtn from "../../assets/deletebtn.png";
import InputField from "../../components/input/index";
import Button from "../../components/button/index.jsx";
import RoleDropdown from "../../components/dropdown/dropdown.jsx";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../../api/axiousinstance.jsx";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config.jsx";
// import { showToast, Toast } from "../../components/toast/toast.jsx";

const options = [
  { value: "Published", label: "Published" },
  { value: "Unpublished", label: "Unpublished" },
  { value: "Draft", label: "Draft" },
];
const models = [
  { value: 1, label: "Creative Turbo" },
  { value: 2, label: "Fantasy" },
  { value: 3, label: "DraVersatile Luxury" },
  { value: 4, label: "Text Pro" },
];
const ratio = [
  { value: "", label: "-Select-" },
  { value: "1:1", label: "1:1" },
  { value: "1:2", label: "1:2" },
  { value: "1:3", label: "1:3" },
  { value: "4:3", label: "4:3" },
];

function formatErrorMessage(errorData) {
  let message = "";
  for (const [key, value] of Object.entries(errorData)) {
    if (Array.isArray(value)) {
      message += `${key}: ${value.join(", ")}\n`;
    } else if (typeof value === "object") {
      message += formatErrorMessage(value); // Recurse for nested objects
    } else {
      message += `${key}: ${value}\n`;
    }
  }
  return message.trim();
}
function AddNewArtwork(props) {
  const { id, catid } = useParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { state } = location;
  const queryParams = new URLSearchParams(location.search);
  const Cname = queryParams.get("catName");
  const catId = queryParams.get("catId");

  // const CCname = Number(Cname)
  // console.log(props.name)
  const data = state ? state.data : null;
  // console.log(data)
  const type = props.type;
  // console.log("data", props);
  const initialFormData =
    type === "edit"
      ? {
          title: id,
          sorting: data.sorting,
          artwork: data.s3_url_artwork_image,
          featuredtitle: data.name,
          featuredsorting: data.sorting,
          artwork_image: data.artwork_image,
          prompt: data.prompt,
          negativeprompt: data.negative_prompt,
          aimodel: data.ai_model,
          ratio: data.ratio,
          // promptsorting: data.promptsorting,
          status: data.status,
        }
      : {
          title: id,
          sorting: "",
          artwork_image: "",
          featuredtitle: "",
          featuredsorting: "",
          artwork: "",
          prompt: "",
          negativeprompt: "",
          aimodel: "",
          ratio: "",
          promptsorting: "",
          status: "Published",
        };
  const [formData, setFormData] = useState(initialFormData);
  // console.log(formData.artwork_image);
  const handleDelete = () => {
    const token = localStorage.getItem("access");
    axiosInstance
      .delete(`/api-admin/featured_artwork/artworkmodel/${catId}/delete/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error deleting artwork:", error);
      });
  };
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
    // console.log("button pressed");
  };

  const handleRoleChange = (name, selectedOption) => {
    // console.log(`Changing ${name} to:`, selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
  };

  const handleChange = (e) => {
    const { name, type } = e.target;
    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevState) => ({
        ...prevState,
        [name]: file,
      }));

      // reader.readAsDataURL(file);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    }
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("access");
    // console.log(formData);

    if (type === "edit") {
      // console.log("formdata", formData),
        axiosInstance
          .patch(
            `/api-admin/featured_artwork/artworkmodel/${catId}/change/`,
            {
              category: id,
              name: formData.featuredtitle,
              prompt: formData.prompt,
              sorting: formData.sorting,
              artwork: formData.artwork_image,
              negative_prompt: formData.negativeprompt,
              ai_model: formData.aimodel,
              ratio: formData.ratio,
              status: formData.status,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
          // console.log("response", response)
            const newCategory = response.data;
            setFormData(newCategory);
            setLoading(false);
            toast.success("Category updated successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              navigate(-1);
            }, 2000);
            // toast("Category updated successfully");
            // navigate(-1);
            //  window.location.href = `/featuredArtwork/prompt/${id}`;
          })
          .catch((error) => {
              const errorMessage = formatErrorMessage(error.response.data);
              toast(errorMessage);
              // console.log(error.response.data);
              setLoading(false);
          });
    } else {
      const formDataToSend = new FormData();
      formDataToSend.append("category", id);
      formDataToSend.append("name", formData.featuredtitle);
      formDataToSend.append("prompt", formData.prompt);
      formDataToSend.append("sorting", formData.sorting);
      if (formData.artwork) {
        formDataToSend.append("artwork_image", formData.artwork);
      }
      formDataToSend.append("negative_prompt", formData.negativeprompt);
      formDataToSend.append("ai_model", formData.aimodel);
      formDataToSend.append("ratio", formData.ratio);
      formDataToSend.append("status", formData.status);

      axiosInstance
        .post(`/api-admin/featured_artwork/artworkmodel/add/`, formDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setLoading(false);
          // console.log("response")
          toast.success("Category added successfully", {
           position: "top-right",
           autoClose: 2000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
         });
           setTimeout(() => {
             navigate(-1);
           },2000);
        })
        .catch((error) => {
          const errorResponse = error.response?.data.errors;
          if (errorResponse) {
            const errorKeys = Object.keys(errorResponse);
            toast.error("Required Fields: " + errorKeys.join(", "));
          } else {
            toast.error("Something went wrong while adding the artwork.");
          }
          // console.error("Error details:", errorResponse);
          setLoading(false);
        });
    }
  };
  return (
    <>
      {/* <Toast /> */}
      <Dashboard>
        <div className={style.formContainer}>
          <div className={style.header}>
            <button onClick={handleBackClick}>
              <img src={backspace} alt="" />
              <span> &nbsp;{Cname}</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={style.mainForm}>
              <div className={style.categoryContainer}>
                <div className={style.catImage}>
                  <p>Category</p>
                  {type == "edit" ? (
                    <img src={deletebtn} alt="" onClick={handleDelete} />
                  ) : null}
                </div>
                <div className={style.categoryInput}>
                  <InputField
                    label="Title"
                    type="text"
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                    value={Cname}
                    readOnly={true}
                  />
                  {/* <InputField
                  label="Sorting"
                  type="number"
                  name="sorting"
                  placeholder="00"
                  onChange={handleChange}
                  value={formData.sorting}
                /> */}
                </div>
              </div>
              <hr />
              <div className={style.categoryContainer}>
                <p>Add Featured Arts</p>
                <div className={style.categoryInput}>
                  <InputField
                    label="Title"
                    type="text"
                    name="featuredtitle"
                    placeholder="Title"
                    onChange={handleChange}
                    value={formData.featuredtitle}
                  />
                  <InputField
                    label="ArtWork "
                    type="file"
                    name="artwork"
                    onChange={handleChange}
                    // defaultValue = {profile}
                    // value={formData.artwork }
                  />
                  <InputField
                    label="Sorting"
                    type="number"
                    name="sorting"
                    placeholder="00"
                    onChange={handleChange}
                    value={formData.sorting}
                  />
                </div>
              </div>
              <hr />
              <div className={style.categoryContainer}>
                <p>Premade Prompt</p>
                <div className={style.Prompt}>
                  <InputField
                    label="Prompt"
                    type="text"
                    name="prompt"
                    placeholder="Prompt"
                    className={style.promptinput}
                    onChange={handleChange}
                    value={formData.prompt}
                  />
                </div>
                <div className={style.negative}>
                  <InputField
                    label="Negative Prompt"
                    type="text"
                    name="negativeprompt"
                    placeholder="Negative Prompt"
                    onChange={handleChange}
                    value={formData.negativeprompt}
                  />
                  {/* <RoleDropdown
                    label="AI Model"
                    name="aimodel"
                    className1={style.dropdownMain}
                    className2={style.labelDropdown}
                    className={style.modelOptions}
                    options={models}
                    value={formData.aimodel}
                    defaultValue={
                      formData.aimodel ? formData.aimodel : models[0].value
                    }
                    onChange={(selectedOption) =>
                      handleRoleChange("aimodel", selectedOption)
                    }
                  /> */}
                </div>
                <div className={style.ratioSortingStatus}>
                  <RoleDropdown
                    label="Ratio"
                    name="ratio"
                    className1={style.dropdownMain}
                    className2={style.labelDropdown}
                    className={style.statusOptions}
                    options={ratio}
                    value={formData.ratio}
                    onChange={(selectedOption) =>
                      handleRoleChange("ratio", selectedOption)
                    }
                  />
                  {/* <InputField
                  label="Sorting"
                  type="number"
                  name="promptsorting"
                  placeholder="00"
                  onChange={handleChange}
                  value={formData.promptsorting}
                /> */}
                  <RoleDropdown
                    label="Status"
                    name="status"
                    className1={style.dropdownMain}
                    className2={style.labelDropdown}
                    className={style.featuredStatus}
                    options={options}
                    value={formData.status}
                    onChange={(selectedOption) =>
                      handleRoleChange("status", selectedOption)
                    }
                  />
                </div>
              </div>
              <div className={style.buttonContainer}>
                <Button
                  text="Cancel"
                  type="button"
                  onClick={handleBackClick}
                  className={style.cancelButton}
                />
                {/* <Button
                  text="Save"
                  type="submit"
                  className={style.saveButton}
                /> */}
                <button
                  type="submit"
                  className={`btn btn-primary w-80 ${style.saveButton}`}
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin"></i> // Example using Font Awesome spinner
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dashboard>
      <ToastContainer/>
    </>
  );
}

export default AddNewArtwork;
