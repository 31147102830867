/*
File: this file is responsible for checking authentication of user,  login page
Updated at:
changes: 
Date: 18-07-2024
Author: Ahmad,
*/
// dependencies,

import styles from "./index.module.scss";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
// import { Toast, showToast } from "../../components/toast/toast";
import { API_BASE_URL } from "../../config.jsx";
import { Flip } from "react-toastify";
import axiosInstance from "../../api/axiousinstance.jsx";
import {toast, ToastContainer} from "react-toastify"

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Please enter your email address"),
  password: Yup.string().min(8).required("Please enter your password"),
});

function Login() {
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, actions) => {
      // setLoading(true);
      try {
        const response = await axiosInstance.post(
          `/api-admin/login/`,

          {
            email: values.email,
            password: values.password,
          }
        );
        localStorage.setItem("access", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        localStorage.setItem("firstname", response.data.user.first_name);
        localStorage.setItem("lastname", response.data.user.last_name);
        const data = response.data.detail;
        // console.log(data)
        const type = "success";
        // showToast(data, type);
        navigate("dashboard");
      } catch (error) {
        const errorMsg = error.response.data.error[0];
        const type = "error";
        toast.error(errorMsg)
        // console.log(errorMsg);
        // setApiError(errorMsg);
        // showToast(errorMsg, type);
        // console.error("Login error:", errorMsg);
      } finally {
        setLoading(false);
        // actions.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <ToastContainer/>
      <div className={styles.loginMain}>
        <div className={styles.login}>
          <div>
            <div className={styles.logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={styles.p}>
              <p>Please enter your email and password</p>
            </div>
          </div>
          <div className="container">
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.formStart}>
                <label htmlFor="id1">Email address:</label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  id="id1"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email ? (
                  <span className={styles.error}>{formik.errors.email}</span>
                ) : null}
              </div>
              <div className={styles.formStart}>
                <label htmlFor="id2">Password:</label>
                <input
                  className="form-control"
                  type="password"
                  id="id2"
                  placeholder="Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.errors.password && formik.touched.password ? (
                  <span className={styles.error}>{formik.errors.password}</span>
                ) : null}
              </div>
              <div className="container">
                <div className="row">
                  <button
                    type="submit"
                    className={`btn btn-primary w-80 ${styles.buttonMargin}`}
                    disabled={formik.isSubmitting}
                  >
                    {loading ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
